<i18n>
{
  "en": {
    "browser_title": "Sign Up",
    "signup": "Sign Up Information",
    "tab_download_plans": "Download Plans",
    "tab_streaming_plans": "Streaming Plans"
  },
  "ja": {
    "browser_title": "入会案内",
    "signup": "入会のご案内",
    "tab_download_plans": "ダウンロードプラン",
    "tab_streaming_plans": "ストリーミングプラン"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />
  <join-image />
  <div class="contents">
    <h1 @click="getClientID()">{{ $t('signup') }}</h1>
    <!-- hidden UI interaction to expose GA Client ID without poking into debug (for sales)
         click/tap on the join header text and it will show the CID every 8th time -->
    <div v-if="CID.isVisible">{{ CID.val }}</div>

    <!-- new experience: only show streaming-only packages -->
    <div class="join-plan" v-if="newXPEnabled">
      <join-table currency="USD" package-type="join-streaming" />
    </div>

    <!-- classic experience: show download and streaming packages -->
    <div class="join-plan" v-else>
      <!-- plan tables (desktop): download tab appears first -->
      <vue-tabs class="tab-scroll" v-if="deviceType === 'desktop'">
        <v-tab :title="$t('tab_download_plans')">
          <join-table currency="USD" package-type="join" />
        </v-tab>
        <v-tab :title="$t('tab_streaming_plans')">
          <join-table currency="USD" package-type="join-streaming" />
        </v-tab>
      </vue-tabs>
      <!-- plan tables (mobile): streaming tab appears first -->
      <vue-tabs class="tab-scroll" v-else>
        <v-tab :title="$t('tab_streaming_plans')">
          <join-table currency="USD" package-type="join-streaming" />
        </v-tab>
        <v-tab :title="$t('tab_download_plans')">
          <join-table currency="USD" package-type="join" />
        </v-tab>
      </vue-tabs>
    </div>
    <div class="button-box button-box--center" v-if="0">
      <button class="button-ghost-fill button-default button-medium">全プラン比較表</button>
    </div>
    <section class="json-text section-wide">
      <div v-html="jsonHtml[locale]"></div>
    </section>
  </div>
</main>
</template>

<script>
/* eslint max-len: 0 */

import UAParser from 'ua-parser-js';
import vueHeadful from 'vue-headful';
import { VueTabs, VTab } from 'vue-nav-tabs';
import APP_CONFIG from '@/appConfig';
import JoinImage from '@/components/join/joinImage.vue';
import JoinTable from '@/components/join/joinTable.vue';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  components: {
    VueTabs,
    VTab,
    'vue-headful': vueHeadful,
    'join-image': JoinImage,
    'join-table': JoinTable,
  },
  data() {
    return {
      dynamicTitle: '',
      jsonHtml: {},
      CID: {
        val: null,
        isVisible: false,
        clickCount: 0,
      },
      deviceType: 'desktop',
    };
  },
  created() {
    this.refreshHTML();
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    // redirect to upgrade if they are a site member
    if (this.isSiteMember) this.$router.push({ name: 'upgrade' });

    // get device type
    const parser = new UAParser();
    const device = parser.getDevice();
    // UAParser returns separate 'mobile' and 'tablet' device types (and a few others). Fixes a
    // bug we had where packages, were not appearing for iPads since those were returning 'tablet'
    // device types here, but the package list only designates 'mobile' types or 'desktop'. I
    // doubt Sales will be requesting specific packages for 'tablet' (unless they want to track
    // those separately) so we're just folding all devices as 'mobile' here:
    this.deviceType = device.type ? 'mobile' : 'desktop';
  },
  methods: {
    async refreshHTML() {
      const bfAPI = new BifrostAPI();
      const templates = await bfAPI.getStaticPage();
      this.jsonHtml = templates.join;
    },
    getClientID() {
      if (this.CID.clickCount === 0) {
        this.$analytics.getClientId().then((clientId) => {
          this.CID.val = clientId;
        });
      }
      if (this.CID.clickCount === 8) this.CID.isVisible = true;
      this.CID.clickCount += 1;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEnabled() {
      return (process.env.VUE_APP_NEW_XP_ENABLED === 'true');
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_join.scss';

.json-text >>> ul {
  list-style: disc;
}
.json-text >>> ol {
  list-style: decimal;
}
.json-text >>> li {
  margin-left: 1.4em;
}
</style>
